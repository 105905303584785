.tic-tac-toe-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.board-cell {
  border: 1px solid #555;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.winner {
  padding: 2rem 1rem;
  font-size: 2rem;
}

.player-choices {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  width: 100%;

  button {
    cursor: pointer;
    transition: all .5s;

    svg {
      

      .white-fill {
        transition: all .75s ease;
        stroke: white;
        stroke-width: 1px;
      }
    }

    &:hover {
      .white-fill {
        stroke: rgba(16, 121, 16, 0.9);
        stroke-width: 12px;
        fill: rgb(218, 218, 218);
      }
    }
  }
}
