$color-shadow: #222;
$color-button: #1515fa;

.dadtini-generator {
  flex-grow: 1;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    cursor: pointer;
    font-size: 1.5rem;
    border: none;
    border-radius: 9px;
    padding: .5rem 1.5rem;
    margin-bottom: 1rem;
    color: white;
    box-shadow: 0 0 7px $color-shadow;
    background-color: $color-button;

    &:hover {
      box-shadow: 0 0 1px $color-shadow;
    }
  }
}

.dadtini-display {
  width: 100%;
  max-width: 1024px;
  padding: 3rem 0;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: fadeIn;
  animation-duration: .5s;
  overflow: hidden;
  flex-grow: 1;

  &__image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 1024px;
    overflow: hidden;
    margin: 0 auto;
    z-index: 0;
    max-height: 70vh;
  }

  &__image {
    max-width: 100%;
    animation-name: fadeIn;
    opacity: 0;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    &-set-credit {
      opacity: .2;
      animation-name: fadeIn;
      animation-duration: 6s;
      border-radius: .5rem;
      box-shadow: 0 0 6px #666;
      position: absolute;
      top: 2rem;
      right: 2rem;
    }

    &-credit {
      background-color: rgba(6,6,6,.6);
      color: white;
      position: absolute;
      top: 4rem;
      left: 50%;
      margin-left: -200px;
      width: 400px;
      opacity: 0;
      transition: all .75s;

      &--visible {
        opacity: .4;
      }
    }

  }

  &__description {
    animation-name: fadeIn;
    animation-duration: .75s;
    background-color: rgba(200,200,200,.8);
    z-index: 10;
    padding: 2rem;
    border-radius: 2rem;
    box-shadow: 0 0 6px #666;
    max-width: 70%;
  }
}

@keyframes fadeIn {
  from {
    opacty: 0;
  }

  to {
    opacity: .9;
  }
}
