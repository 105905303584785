.page--games {
  font-family: 'Courier New', Courier, monospace;
}

.games-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    display: block;
  }
}
