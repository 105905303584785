body, html {
  width: 100%;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: Arial, Helvetica, sans-serif;
  align-items: stretch;
  justify-content: center;
  text-align: center;
  touch-action: manipulation;

  .title {
    font-size: 5rem;
    margin: 0 0 1rem 0;
  }
}

.button {
  cursor: pointer;
  font-size: 1.25rem;
  border: none;
  border-radius: 9px;
  padding: .5rem 1.5rem;
  margin-bottom: 1rem;
  color: white;
  box-shadow: 0 0 7px #999;
  background-color: gray;

  &:hover {
    box-shadow: 0 0 1px #999;
  }
}

.nav {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      list-style: none;

      a {
        padding: .5rem 1rem;
      }
    }
  }
}
